@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
/* Importing Bootstrap SCSS file. */
@font-face {
  font-family: "Noto Sans Kufi Arabic";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/custom/ArbFONTS-NotoKufiArabic-Regular.ttf);
}
@font-face {
  font-family: "Noto Sans Kufi Arabic";
  font-style: normal;
  font-weight: 700;
  src: url(/assets/fonts/custom/ArbFONTS-NotoKufiArabic-Bold.ttf);
}
html:lang(ar) body, html:lang(ar) html, html:lang(ar) mat-form-field {
  font-family: "Noto Sans Kufi Arabic", Courier, monospace !important;
  direction: rtl;
  text-align: right;
  height: 100%;
  margin: 0;
}
html:lang(ar) .text-default {
  color: #4831d4;
}
html:lang(ar) .btn-default {
  background-color: #4831d4;
  color: #fff;
  border: 1px solid #4831d4;
}
html:lang(ar) .btn-default:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #fff;
  color: #4831d4;
  border: 1px solid #4831d4;
}
html:lang(ar) .btn-default:hover .notification {
  transition: background-color 0.5s ease-in-out;
  background-color: #4831d4;
  color: #fff;
}
html:lang(ar) .btn-outline-default:hover {
  transition: background-color 0.5s ease-in-out;
  background-color: #4831d4;
  color: #fff;
  border: 1px solid #4831d4;
}
html:lang(ar) .btn-outline-default:hover .notification {
  transition: background-color 0.5s ease-in-out;
  background-color: #fff;
  color: #4831d4;
}
html:lang(ar) .btn-outline-danger, html:lang(ar) .btn-outline-success, html:lang(ar) .btn-outline-warning {
  transition: background-color 0.5s ease-in-out;
}
html:lang(ar) .btn-outline-danger:hover .mat-icon, html:lang(ar) .btn-outline-success:hover .mat-icon, html:lang(ar) .btn-outline-warning:hover .mat-icon {
  color: #fff !important;
}
html:lang(ar) .btn-outline-default {
  background-color: #fff;
  border: 1px solid #4831d4;
  color: #4831d4;
}
html:lang(ar) .card .card-header {
  border-bottom: 1px solid transparent;
}
html:lang(ar) .card .card-header:hover {
  color: #4831d4;
}
html:lang(ar) .card .card-body {
  padding: 40px;
}
html:lang(ar) form mat-form-field {
  width: 100%;
  height: 50px !important;
}
html:lang(ar) form mat-form-field .mat-form-field-wrapper {
  padding-bottom: 5px !important;
}
html:lang(ar) form mat-form-field .password-icons, html:lang(ar) form mat-form-field .show-hide {
  position: absolute;
  left: 5px;
  top: 10px;
}
html:lang(ar) form mat-form-field .password-icons span, html:lang(ar) form mat-form-field .show-hide span {
  margin: 3px;
  color: #2b2b2b;
}
html:lang(ar) form .show-hide {
  right: auto;
  left: 20px;
}
html:lang(ar) form .show-hide > span.show:before {
  content: "عرض";
}
html:lang(ar) form .show-hide > span:before {
  content: "إخفاء";
}
html:lang(ar) form div.errors-container p.error-element {
  font-size: 75%;
  color: #f44336;
}
html:lang(ar) form div.errors-container p.error-element::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f06a";
  margin-left: 3px;
}
html:lang(ar) .mat-menu-item::-webkit-scrollbar {
  width: 2px !important;
}
html:lang(ar) .mat-menu-item::-webkit-scrollbar-track {
  background: transparent;
}
html:lang(ar) .mat-menu-item::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 30px;
}
html:lang(ar) .mat-menu-item::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
html:lang(ar) .table-responsive, html:lang(ar) .example-table-container {
  overflow-x: scroll !important;
}
html:lang(ar) .table-responsive::-webkit-scrollbar, html:lang(ar) .example-table-container::-webkit-scrollbar {
  height: 4px !important;
}
html:lang(ar) .table-responsive::-webkit-scrollbar-track, html:lang(ar) .example-table-container::-webkit-scrollbar-track {
  background: #f9f9f9;
}
html:lang(ar) .table-responsive::-webkit-scrollbar-thumb, html:lang(ar) .example-table-container::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 30px;
}
html:lang(ar) .table-responsive::-webkit-scrollbar-thumb:hover, html:lang(ar) .example-table-container::-webkit-scrollbar-thumb:hover {
  background: #cacaca;
}
html:lang(ar) .table-responsive table th, html:lang(ar) .example-table-container table th {
  min-width: 150px;
  background-color: #f4f3fc;
  border-color: #f4f3fc;
  color: #4831d4;
}
html:lang(ar) .table-responsive table th, html:lang(ar) .table-responsive table td, html:lang(ar) .example-table-container table th, html:lang(ar) .example-table-container table td {
  height: 50px;
}
html:lang(ar) .table-responsive .mat-table, html:lang(ar) .example-table-container .mat-table {
  margin: auto;
}
html:lang(ar) .table-responsive tr.mat-header-row, html:lang(ar) .example-table-container tr.mat-header-row {
  background-color: #f4f3fc;
  border-color: #f4f3fc;
}
html:lang(ar) .table-responsive tr.mat-header-row th, html:lang(ar) .example-table-container tr.mat-header-row th {
  color: #4831d4;
  text-align: center;
  font-size: 14px;
  font-family: "Noto Sans Kufi Arabic";
}
html:lang(ar) .table-responsive td.mat-cell, html:lang(ar) .example-table-container td.mat-cell {
  text-align: center;
}
html:lang(ar) .form-group input {
  height: 50px;
  border: 1px solid #ccc;
}
html:lang(ar) .form-group input::placeholder {
  color: #ddd;
  font-size: 13px;
}
html:lang(ar) .form-group input:focus, html:lang(ar) .form-group input:hover {
  box-shadow: none;
  border: 1px solid #4831d4;
  outline: none;
}
html:lang(ar) .eye {
  position: relative;
  float: left !important;
  bottom: 35px;
  left: 15px !important;
}
html:lang(ar) .eye .mat-icon {
  color: #4831d4;
  vertical-align: middle;
  margin-top: 0px;
}
html:lang(ar) .eye .mat-icon.visibility-off {
  color: black;
}
html:lang(ar) .dropzone.is-invalid {
  border-color: red;
  margin-bottom: 20px;
}
html:lang(ar) .demo-toggles {
  display: flex;
  flex-direction: column;
}
html:lang(ar) .demo-toggles * {
  margin-bottom: 16px;
}
html:lang(ar) .demo-options {
  display: flex;
  flex-direction: column;
  width: 600px;
}
html:lang(ar) .demo-data * {
  margin: 16px 0;
}
html:lang(ar) .demo-paginator {
  width: 600px;
}
html:lang(ar) .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links {
  overflow-y: auto;
  overflow-x: hidden;
}
html:lang(ar) .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .logo-wrapper, html:lang(ar) .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .logo-icon-wrapper {
  padding: 31px;
  background-color: #fff;
}
html:lang(ar) .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .according-menu i {
  transform: rotate(180deg);
}
html:lang(ar) .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon div.sidebar-wrapper .sidebar-main .sidebar-links > li:first-child {
  display: block !important;
}
html:lang(ar) .rtl .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .according-menu {
  right: unset;
  top: 15px !important;
}
html:lang(ar) .rtl .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon .sidebar-wrapper .sidebar-main .sidebar-links > li a svg {
  margin-right: unset;
  margin-left: 0px !important;
}
html:lang(ar) .mat-option.abnormal {
  display: none;
}
html:lang(ar) .mat-option.normal {
  background-color: transparent;
}
html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-infix {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
}
html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-infix:active, html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-infix:hover {
  border: 1px solid #4831d4;
}
html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
}
html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-label {
  margin-top: 10px !important;
  margin-right: 10px !important;
}
html:lang(ar) .mat-select-value {
  height: 30px;
  text-indent: 10px;
  padding-top: 10px;
}
html:lang(ar) .mat-select {
  height: 40px;
}
html:lang(ar) .mat-select-arrow-wrapper {
  padding-top: 10px;
  padding-left: 10px;
}
html:lang(ar) a, html:lang(ar) button, html:lang(ar) .mat-icon {
  cursor: pointer;
}
html:lang(ar) .mat-icon {
  font-size: 22px;
  margin: 2px auto 0px auto;
}
html:lang(ar) .cdk-global-overlay-wrapper {
  overflow-y: auto !important;
}
html:lang(ar) :focus {
  outline-color: transparent;
}
html:lang(ar) .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #5746c8;
}
html:lang(ar) .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #f4f3fc;
}
html:lang(ar) .mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}
html:lang(ar) .page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  right: 22px;
}
html:lang(ar) .mat-menu-item:hover:not([disabled]) {
  background-color: transparent !important;
}
html:lang(ar) .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(233, 233, 233, 0.2), 0px 4px 5px 0px rgba(233, 233, 233, 0.2), 0px 1px 10px 0px rgba(233, 233, 233, 0.2);
}
html:lang(ar) .mat-menu-item {
  line-height: 38px !important;
  min-height: 100px !important;
}
html:lang(ar) .mat-menu-item .mat-menu-ripple {
  visibility: hidden !important;
}
html:lang(ar) .text-pink {
  color: #ea2087;
}
html:lang(ar) .btn-pink {
  border-radius: 5px;
  background-color: rgba(234, 32, 135, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ea2087;
}
html:lang(ar) .btn-pink:hover {
  background-color: rgba(234, 32, 135, 0.15);
  color: #ea2087;
}
html:lang(ar) .btn-outline-pink {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ea2087;
  border: 1px solid #ea2087;
}
html:lang(ar) .btn-outline-pink:hover {
  background-color: rgba(234, 32, 135, 0.15);
  border: 1px solid rgba(234, 32, 135, 0.15);
  color: #ea2087;
}
html:lang(ar) .badge-pink {
  color: #fff;
  background-color: #ea2087;
  margin: auto;
  text-align: center;
}
html:lang(ar) .text-purple {
  color: #ae18b8;
}
html:lang(ar) .btn-purple {
  border-radius: 5px;
  background-color: #ae18b83d;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ae18b8;
}
html:lang(ar) .btn-purple:hover {
  background-color: #ae18b83d;
  color: #ae18b8;
}
html:lang(ar) .btn-outline-purple {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ae18b8;
  border: 1px solid #ae18b8;
}
html:lang(ar) .btn-outline-purple:hover {
  background-color: #ae18b83d;
  border: 1px solid #ae18b83d;
  color: #ae18b8;
}
html:lang(ar) .left-header .level-menu .header-level-menu {
  width: 250px;
}
html:lang(ar) .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}
html:lang(ar) img.delete {
  filter: brightness(0) saturate(100%) invert(45%) sepia(43%) saturate(3968%) hue-rotate(303deg) brightness(96%) contrast(89%);
}
html:lang(ar) img.edit {
  filter: brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(6330%) hue-rotate(254deg) brightness(89%) contrast(85%);
}
html:lang(ar) .mat-radio-label-content {
  font-family: "Noto Sans Kufi Arabic";
}
html:lang(ar) .mat-radio-button {
  border-radius: 5px;
  display: block !important;
}
html:lang(ar) .toast-success {
  background-color: #5546b3 !important;
  border-radius: 8px;
}
html:lang(ar) .form-control[readonly] {
  background-color: #f5f7f9 !important;
  opacity: 1;
  border: 1px solid transparent !important;
}
html:lang(ar) .form-control[readonly]:hover, html:lang(ar) .form-control[readonly]:focus {
  border: 1px solid transparent !important;
}
html:lang(ar) .mat-form-field-appearance-legacy .mat-form-field-ripple {
  height: 0px !important;
}
html:lang(ar) .show-hide {
  top: 60px !important;
}